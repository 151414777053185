<template lang="pug">
  b-row.machines-analytics-report-panel
    b-col.mb-4.mb-xl-0(
      sm='12'
      md='6'
      lg='6'
      xl='6')
      b-card.machines-machine-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.wc-bubble-no-mask.mr-3.shadow(
                :src="$getDeep(panel.rs, 'model.attributes.portrait_url')"
                variant='white'
                @click.native='onMachineRefresh'
                alt='')
                template(v-if="!$getDeep(panel.rs, 'model.attributes.portrait_url')" #default)
                  .wc-bubble-content.wc-bubble-linked.bg-primary
                    .i-wrapper
                      i.fal.fa-fw.fa-2x.fa-conveyor-belt-alt.text-white

            .panel-info.d-flex.flex-column.flex-fill
              .panel-info-serial.font-weight-bold
                | {{ panel.data.attributes.serial }}
              .panel-info-model.small.text-muted(v-if='panel.rs.model')
                | {{ $getDeep(panel.rs, 'model.attributes.name') }}
              .panel-info-data.mt-3
                span.panel-info-organization.small.mr-3(v-if='panel.rs.organization')
                  i.fal.fa-fw.fa-building.text-muted
                  |  {{ $getDeep(panel.rs, 'organization.attributes.name') }}
              .panel-info-report.small.mb-1
                span.font-weight-bold.mr-1
                  i.fal.fa-fw.fa-calendar-day.text-muted
                  |  {{ $t('activerecord.models.machine/report.one') }}
                span.text-muted
                  |  {{ $t('shared.from') }}
                |  {{ $getDeep(panel.meta, 'data.report.from') }}
                span.text-muted
                  |  {{ $t('shared.to') }}
                |  {{ $getDeep(panel.meta, 'data.report.to') }}
              .panel-info-state.align-self-end
                b-badge.state.p-2(
                  :variant="$wc.conf.states.machine.variants[panel.data.attributes.state]"
                  pill)
                  i.fa-fw(:class="$wc.conf.states.machine.icons[panel.data.attributes.state]")
                  |  {{ $t('machine.states.' + panel.data.attributes.state) }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      sm='12'
      md='6'
      lg='6'
      xl='6')
      b-card.machines-machine-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.machine/property.travel_inclination')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-car-tilt
                span.font-weight-bold
                  | {{ $t('activerecord.attributes.machine/property.travel_inclination') }}
                span
                  |  {{ $getDeep(panel.rs.property, 'attributes.travel_inclination') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.machine/property.lateral_inclination')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-car-tilt
                span.font-weight-bold
                  | {{ $t('activerecord.attributes.machine/property.lateral_inclination') }}
                span
                  |  {{ $getDeep(panel.rs.property, 'attributes.lateral_inclination') }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.machine/property.battery')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-car-battery
                span.font-weight-bold
                  | {{ $t('activerecord.attributes.machine/property.battery') }}
                span
                  |  {{ $getDeep(panel.rs.property, 'attributes.battery') }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'machines-analytics-report-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  props: {
    data: Object,
    show: Boolean,
  },
  watch: {
    panel() {
      this.panel.rs.organization = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.organization.data.type'),
        this.$getDeep(this.panel.data, 'relationships.organization.data.id')
      )
      this.panel.rs.model = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.model.data.type'),
        this.$getDeep(this.panel.data, 'relationships.model.data.id')
      )
      this.panel.rs.property = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.property.data.type'),
        this.$getDeep(this.panel.data, 'relationships.property.data.id')
      )
    },
  },
  data() {
    return {
      cacheType: 'machines/analytics/report/getPanel',
      cachePayload: {
        machine_id: this.$route.params.machine_id,
        from: this.$route.query.from,
        to: this.$route.query.to,
      },
    }
  },
}
</script>
