<template lang="pug">
  header#header.lr-header
    b-navbar#mainbar.p-0(ref='headerMainbar' toggleable='lg' type='light' variant='primary')
      b-navbar-brand#mainbar-brand.d-inline-flex.align-items-center.m-0.p-0.pl-4.mr-auto
        figure.figure.brand.d-flex.mb-0
          .wc-i.wc-symbol.rounded
        .wc-connect.text-uppercase.text-white.ml-2
          | {{ $wc.conf.base.name }}
</template>

<script>
export default {
  name: 'lr-header',
}
</script>
