<template lang="pug">
  #wrapper.l-report.l-wrapper
    #wrapper-inner.l-wrapper-inner
      #content.l-content
        report-header
        slot
        report-footer
</template>

<script>
import ReportHeader from '@components/layouts/report/Header'
import ReportFooter from '@components/layouts/report/Footer'

export default {
  name: 'l-report',
  components: {
    ReportHeader,
    ReportFooter,
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

@media print {
  .navbar {
    display: block;
  }
  a {
    &:not(.btn) {
      text-decoration: none;
    }
  }
  .badge {
    border: none;
  }
}

.l-report {
  #wrapper-inner {
    padding-left: 0;
  }
  .l-content {
    background-color: $wc-bg;
  }
  .l-section {
    > .l-section-h {
      padding-top: 1.5rem;
    }
    &:last-child > .l-section-h:last-child {
      padding-bottom: 1.5rem;
    }
  }
}
</style>
