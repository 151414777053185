<template lang="pug">
  layout-report
    main#main.machines-analytics-report
      b-container(fluid)
        wc-breadcrumb(icon='fal fa-conveyor-belt-alt')
        section.l-section
          .l-section-h.machines-analytics-report
            b-row.mb-xl-5
              b-col
                report-panel(ref='reportPanel')
            b-row.mb-xl-5
              b-col
                router-view
            
</template>

<script>
import LayoutReport from '@components/layouts/Report'
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import ReportPanel from '@views/container/machines/analytics/report/Panel'

export default {
  name: 'machines-analytics-report',
  components: {
    LayoutReport,
    WcBreadcrumb,
    ReportPanel,
  },
  mounted() {
    this.$cable.connection.disconnect()
  },
}
</script>
